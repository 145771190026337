import { action, thunk } from 'easy-peasy';

import getReservations from '../../services/reservations/get';
import { postReservation, postCancelReservation } from '../../services/reservations/post';

import { sortByNumberItems } from '../../shared/utilities';

const reservationsModel = {
  items: {
    upcoming: [],
    past: [],
    cancelled: []
  },
  loading: false,
  loadingCard: null,
  error: '',
  getReservations: thunk(async (actions) => {
    actions.setLoading(true);
    actions.setError('');
    return await getReservations(actions);
  }),
  storeGetReservations: action((state, payload) => {
    const todayTime = new Date().getTime();
    state.items.upcoming = payload.filter(reservation => {
      const arrivalTime = new Date(`${reservation.date.split('T')[0]}T${reservation.schedule.arrivalTime}`);
      return ['pending', 'approved', 'onboard', 'underway'].includes(reservation.status) && todayTime < arrivalTime;
    }).sort((a, b) => sortByNumberItems(a, b, 'dateEpoch', 'asc'));
    state.items.past = payload.filter(reservation => {
      const arrivalTime = new Date(`${reservation.date.split('T')[0]}T${reservation.schedule.arrivalTime}`);
      return ['finished', 'cancelled'].includes(reservation.status) || todayTime > arrivalTime;
    }).sort((a, b) => sortByNumberItems(a, b, 'dateEpoch', 'desc'));
  }),
  postReservation: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    await postReservation(actions, payload);
  }),
  postCancelReservation: thunk(async (actions, payload) => {
    actions.setLoadingCard(payload);
    actions.setError('');
    return await postCancelReservation(actions, payload);
  }),
  storePostCancelReservation: action((state, payload) => {
    state.items.cancelled.push(state.items.upcoming.find(reservation => reservation.id === payload.reservationId));
    state.items.upcoming = state.items.upcoming.filter(reservation => reservation.id !== payload.reservationId);
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setLoadingCard: action((state, payload) => {
    state.loadingCard = payload ? payload.reservationId : payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default reservationsModel;
