import React from 'react';

import { makeStyles, withTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    height: '100vh',
    left: 0,
    position: 'fixed',
    width: '100vw',
  },
  svg: {
    bottom: 0,
    left: 0,
    minWidth: '100vw',
    position: 'absolute',
    // Portrait
    '@media (orientation: portrait)': {
      width: '1010px',
      marginLeft: '50%',
      transform: 'translateX(-50%)',
    },
    [`${ theme.breakpoints.up('sm') } and (orientation: portrait)`]: {
      width: '1820px',
    },
  },
}));

const BackgroundIllustration = props => {
  const { theme } = props;
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <svg className={ classes.svg } viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <rect width="1920" height="1080" fill={ theme.palette.background.default }/>
        <path d="M1920 652H1453.24C1435.78 652 1418.38 654.012 1401.38 657.995V657.995C1298.64 682.075 1226 773.712 1226 879.235V1033H1920L1920 652Z" fill={ theme.palette.primary.light }/>
        <path d="M1399.03 344C1385.21 344 1374 355.193 1374 369C1374 382.807 1385.21 394 1399.03 394H1920V344H1399.03Z" fill={ theme.palette.primary.light }/>
        <path d="M1708.03 394C1694.21 394 1683 405.193 1683 419C1683 432.807 1694.21 444 1708.03 444H1920V394L1708.03 394Z" fill={ theme.palette.primary.light }/>
        <path d="M-36 169H405.902C481.459 169 554.916 193.869 614.933 239.768C700.064 304.872 750 405.925 750 513.098V1577H-36L-36 169Z" fill={ theme.palette.primary.light }/>
        <rect x="990.856" y="726" width="415.575" height="415.575" rx="48" transform="rotate(45 990.856 726)" fill={ theme.palette.primary.dark }/>
        <rect x="1237.23" y="648" width="458.531" height="458.531" rx="48" transform="rotate(45 1237.23 648)" fill={ theme.palette.primary.dark }/>
        <rect x="657.425" y="726" width="347.084" height="347.084" rx="48" transform="rotate(45 657.425 726)" fill={ theme.palette.primary.main }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1280 844C1280 840.686 1277.31 838 1274 838C1270.69 838 1268 840.686 1268 844V907.033C1260.45 904.521 1255 897.397 1255 889L1255 880C1255 876.686 1252.31 874 1249 874C1245.69 874 1243 876.686 1243 880V889C1243 904.068 1253.75 916.625 1268 919.42V944C1268 947.314 1270.69 950 1274 950C1277.31 950 1280 947.314 1280 944V914V899.852C1295.16 898.347 1307 885.556 1307 870V860C1307 856.686 1304.31 854 1301 854C1297.69 854 1295 856.686 1295 860V870C1295 878.919 1288.51 886.323 1280 887.751V844Z" fill={ theme.palette.secondary.light }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M587 888C587 885.791 588.791 884 591 884C593.209 884 595 885.791 595 888L595 941C595 943.209 593.209 945 591 945C588.791 945 587 943.209 587 941L587 925.98C574.209 925.455 564 914.92 564 902V894C564 891.791 565.791 890 568 890C570.209 890 572 891.791 572 894V902C572 910.501 578.629 917.453 587 917.969L587 888Z" fill={ theme.palette.background.default }/>
        <rect y="940" width="1920" height="140" fill={ theme.palette.primary.dark }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M704 695C675.281 695 652 718.281 652 747V884H644V916H636V980H676V916H668V884H660V747C660 722.699 679.699 703 704 703H721V731C721 733.209 722.791 735 725 735C727.209 735 729 733.209 729 731V703H769V731C769 733.209 770.791 735 773 735C775.209 735 777 733.209 777 731V703H806C808.209 703 810 701.209 810 699C810 696.791 808.209 695 806 695H773H725H704Z" fill={ theme.palette.primary.dark }/>
        <rect x="701" y="731" width="96" height="96" rx="16" fill={ theme.palette.secondary.dark }/>
        <path d="M727.667 789.667C727.667 792.013 728.707 794.12 730.333 795.587V800.333C730.333 801.8 731.533 803 733 803H735.667C737.133 803 738.333 801.8 738.333 800.333V797.667H759.667V800.333C759.667 801.8 760.867 803 762.333 803H765C766.467 803 767.667 801.8 767.667 800.333V795.587C769.293 794.12 770.333 792.013 770.333 789.667V763C770.333 753.667 760.787 752.333 749 752.333C737.213 752.333 727.667 753.667 727.667 763V789.667ZM737 792.333C734.787 792.333 733 790.547 733 788.333C733 786.12 734.787 784.333 737 784.333C739.213 784.333 741 786.12 741 788.333C741 790.547 739.213 792.333 737 792.333ZM761 792.333C758.787 792.333 757 790.547 757 788.333C757 786.12 758.787 784.333 761 784.333C763.213 784.333 765 786.12 765 788.333C765 790.547 763.213 792.333 761 792.333ZM765 776.333H733V763H765V776.333Z" fill={ theme.palette.background.default }/>
        <ellipse cx="987.095" cy="981.324" rx="21.676" ry="21.676" fill={ theme.palette.secondary.main }/>
        <ellipse cx="836.447" cy="981.324" rx="21.676" ry="21.676" fill={ theme.palette.secondary.main }/>
        <ellipse cx="906.894" cy="981.324" rx="21.676" ry="21.676" fill={ theme.palette.secondary.dark }/>
        <ellipse cx="1074.88" cy="981.324" rx="21.676" ry="21.676" fill={ theme.palette.secondary.dark }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M894.025 827.424C854.113 827.424 818.323 852.005 803.996 889.256L768.168 982.408H807.205C807.191 982.048 807.185 981.687 807.185 981.324C807.185 965.163 820.286 952.061 836.447 952.061C852.609 952.061 865.71 965.163 865.71 981.324C865.71 981.687 865.703 982.048 865.69 982.408H957.853C957.84 982.048 957.833 981.687 957.833 981.324C957.833 965.163 970.935 952.061 987.096 952.061C1003.26 952.061 1016.36 965.163 1016.36 981.324C1016.36 981.687 1016.35 982.048 1016.34 982.408H1114.98L1153.56 859.834C1158.61 843.773 1146.62 827.424 1129.78 827.424H894.025ZM1124.29 939.056L1145.95 870.234H1058.24C1043.45 870.234 1030.19 879.332 1024.87 893.126L1007.15 939.056H1124.29Z" fill={ theme.palette.secondary.light }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M993.598 952.786C1006.63 955.743 1016.36 967.398 1016.36 981.324C1016.36 981.687 1016.35 982.048 1016.34 982.408H1114.98L1153.56 859.834C1158.61 843.773 1146.62 827.424 1129.78 827.424H1008.77C1033.35 827.424 1032.61 850.726 1014.73 898.955C1003.35 926.05 993.598 952.786 993.598 952.786Z" fill={ theme.palette.secondary.main }/>
        <ellipse cx="1103.48" cy="959.199" rx="8.52405" ry="11.3799" transform="rotate(26.86 1103.48 959.199)" fill={ theme.palette.secondary.dark }/>
        <ellipse cx="1044.28" cy="959.199" rx="8.52405" ry="11.3799" transform="rotate(26.86 1044.28 959.199)" fill={ theme.palette.secondary.dark }/>
        <path d="M1145.95 870.234L1124.29 939.056H1007.15L1024.87 893.126C1030.19 879.332 1043.45 870.234 1058.24 870.234H1145.95Z" fill={ theme.palette.secondary.dark }/>
        <path d="M841.639 891.474C847.379 878.558 859.704 870.718 873.838 870.718H1011L995.766 904.374H835.905L841.639 891.474Z" fill={ theme.palette.secondary.main }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M801.765 872.944C801.765 871.747 802.736 870.776 803.933 870.776L888.469 870.776C889.666 870.776 890.637 871.747 890.637 872.944C890.637 874.141 889.666 875.111 888.469 875.111L803.933 875.111C802.736 875.111 801.765 874.141 801.765 872.944Z" fill={ theme.palette.secondary.main }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M766 868.609C766 867.412 766.97 866.441 768.168 866.441L885.218 866.441C886.415 866.441 887.386 867.412 887.386 868.609C887.386 869.806 886.415 870.776 885.218 870.776L768.168 870.776C766.97 870.776 766 869.806 766 868.609Z" fill={ theme.palette.secondary.light }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M769.251 929.302C769.251 928.104 770.222 927.134 771.419 927.134L888.47 927.134C889.667 927.134 890.637 928.104 890.637 929.302C890.637 930.499 889.667 931.469 888.47 931.469L771.419 931.469C770.222 931.469 769.251 930.499 769.251 929.302Z" fill={ theme.palette.secondary.light }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1237 596C1237.85 576.585 1254 561.087 1273.86 561.087C1278.5 561.087 1282.92 561.97 1287.02 563.516C1299.54 539.465 1324.89 523 1354.15 523C1379.24 523 1401.46 535.109 1415.18 553.725C1423.5 548.363 1433.41 545.218 1444.09 545.218C1472.81 545.218 1496.14 567.818 1497 596H1237Z" fill={ theme.palette.primary.light }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1137 246C1137.49 234.83 1146.81 225.913 1158.26 225.913C1160.94 225.913 1163.49 226.421 1165.86 227.31C1173.08 213.473 1187.71 204 1204.59 204C1219.06 204 1231.88 210.967 1239.8 221.677C1244.6 218.592 1250.32 216.783 1256.47 216.783C1273.04 216.783 1286.51 229.785 1287 246H1137Z" fill={ theme.palette.primary.light }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M771.231 394C735.148 394 704.351 414.612 692.098 443.655C683.285 432.834 669.23 425.795 653.342 425.795C626.643 425.795 605 445.588 605 470H856C855.094 427.888 817.505 394 771.231 394Z" fill={ theme.palette.background.default }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1062.94 648C1082.93 648 1099.98 659.119 1106.77 674.788C1111.65 668.95 1119.43 665.153 1128.23 665.153C1143.01 665.153 1155 675.83 1155 689H1016C1016.5 666.282 1037.32 648 1062.94 648Z" fill={ theme.palette.primary.light }/>
        <circle cx="960" cy="583" r="40" fill={ theme.palette.secondary.dark }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M543.188 693C527.519 693 514.145 701.95 508.823 714.561C504.996 709.862 498.893 706.806 491.993 706.806C480.399 706.806 471 715.4 471 726H580C579.607 707.715 563.283 693 543.188 693Z" fill={ theme.palette.background.default }/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="1920" height="1080" fill={ theme.palette.background.default }/>
        </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default withTheme(BackgroundIllustration);
