import { action, thunk } from 'easy-peasy';

import compareVersions from 'compare-versions';

import getVersion from '../../services/version/get';
import { getUserMetadata } from '../../services/global/get';

import { DEFAULT_SNACKBAR } from '../../shared/utilities';

const globalModel = {
  version: {
    loading: true,
    error: '',
    update: false,
    version: '1.1.0',
  },
  theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
  title: '',
  snackbar: DEFAULT_SNACKBAR,
  lastNotification: { id: '', status: '' },
  setTheme: action((state, payload) => {
    const theme = payload === 'light' ? 'dark' : 'light';
    state.theme = theme ;
    localStorage.setItem('theme', theme);
  }),
  getVersion: thunk(async (actions) => {
    return await getVersion(actions);
  }),
  getUserMetadata: thunk(async (actions, payload) => {
    return await getUserMetadata(actions, payload);
  }),
  storeGetVersion: action((state, payload) => {
    if ( compareVersions(payload, state.version.version) === 1 ) {
      state.version.update = true;
    } else {
      state.version.update = false;
    }
  }),
  storeGetUserMetadata: action((state, payload) => {
    localStorage.setItem('id', payload.id);
    localStorage.setItem('name', payload.name);
    localStorage.setItem('username', payload.username);
    localStorage.setItem('email', payload.email);
    localStorage.setItem('countryId', payload.organization.countryId);
    localStorage.setItem('clientId', payload.client.id);
    localStorage.setItem('clientRsvCreateHours', payload.client.rsvCreateHours);
    localStorage.setItem('clientRsvCancelHours', payload.client.rsvCancelHours);
    localStorage.setItem('clientRsvAllowDelete', payload.client.rsvAllowDelete);
    localStorage.setItem('clientRsvAutoCreate', payload.client.rsvAutoCreate);
  }),
  setVersionLoading: action((state, payload) => {
    state.version.loading = payload;
  }),
  setVersionError: action((state, payload) => {
    state.version.error = payload;
  }),
  storeTitle: action((state, payload) => {
    state.title = payload;
  }),
  setSnackbar: action((state, payload) => {
    state.snackbar = payload;
  }),
  setLastNotification: action((state, payload) => {
    state.lastNotification = payload;
  }),
}

export default globalModel;
