import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import CustomAppBar from '../components/CustomAppBar';

import { formatDate, formatTime, handleEndpointErrors } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  markAllRead: {
    textAlign: 'center',
    '& .MuiButton-root': {
      backgroundColor: 'transparent',
      border: `1px solid ${ theme.palette.primary.light }`,
      borderRadius: '24px',
      boxShadow: 'none',
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  listItem: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    '&:first-of-type': {
      paddingTop: theme.spacing(2),
    },
  },
  listItemUnseen: {
    backgroundColor: theme.palette.secondary.overlay,
  },
  notFound: {
    marginTop: theme.spacing(3),
    '& .MuiListItem-root': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& .MuiSvgIcon-root': {
      opacity: 0.5,
    },
  },
}));

const Notifications = (props) => {
  const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const updateNotifications = useStoreActions(actions => actions.notifications.updateNotifications);

  const handleMarkAllRead = notifications => {
    notifications = notifications.map(notification => ({ id: notification.id, read: true, seen: true }));
    updateNotifications(notifications).then(() => {
      const notificationsState = store.getState().notifications;
      if (!notificationsState.loading && !notificationsState.error) {
        // console.log(notificationsState);
      } else {
        handleEndpointErrors(notificationsState, props, setSnackbar, t);
      }
    });
  }

  const handleNotificationClick = notification => {
    updateNotifications([{ id: notification.id, read: true }]).then(() => {
      const notificationsState = store.getState().notifications;
      if (!notificationsState.loading && !notificationsState.error) {
        props.toggle();
        props.history.push({ pathname: `/reservations/detail/${ notification.dispatchableId }` });
      } else {
        handleEndpointErrors(notificationsState, props, setSnackbar, t);
      }
    });
  }

  const t = useTranslation();

  return (
    <Dialog className={ classes.root } fullScreen open={ props.open } onClose={ props.toggle } TransitionComponent={ Transition }>
      <CustomAppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={ props.toggle } aria-label="close">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={ classes.title }>
            { t('notifications.label') }
          </Typography>
        </Toolbar>
      </CustomAppBar>
      <Toolbar />
      { props.notifications.length ? (
        <React.Fragment>
          { props.notifications.length > 1 ? <div className={ classes.markAllRead }><Button
            variant="contained"
            startIcon={ <CheckIcon /> }
            onClick={ () => handleMarkAllRead(props.notifications) }
          >{ t('notifications.markAllRead') }</Button></div> : null }

          <List disablePadding>
            { props.notifications.map( (notification, index) => (
              <ListItem
                key={ notification.id }
                button
                className={ clsx( classes.listItem, { [classes.listItemUnseen]: !notification.UserNotification.seen } ) }
                onClick={ () => handleNotificationClick(notification) }
                divider={ props.notifications.length !== index + 1 }
              >
                <ListItemText
                  primary={ t('notifications.reservation', { reservation: notification.payload.route.name, time: formatTime(notification.payload.schedule.departureTime), action: t(`global.status.${ notification.action }`) }) }
                  secondary={ t('notifications.ticket', { ticket: notification.payload.ticket, date: formatDate(notification.payload.date, t) }) }
                />
              </ListItem>
            ) ) }
          </List>
        </React.Fragment>
      ) : (
        <List className={ classes.notFound }>
          <ListItem>
            <MailIcon />
          </ListItem>
          <ListItem>
            <ListItemText primary={ t('notifications.notFound') } />
          </ListItem>
        </List>
      ) }
    </Dialog>
  );
}

Notifications.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
};

export default withRouter(Notifications);
