import { createStore } from 'easy-peasy';

import globalModel from './models/global';
import loginModel from './models/login';
import notificationsModel from './models/notifications';
import routesModel from './models/routes';
import reservationsModel from './models/reservations';
import reservationDetailModel from './models/reservationDetail';
import placesModel from './models/places';
import countriesModel from './models/countries';
import vehiclesModel from './models/vehicles';
import schedulesModel from './models/schedules';
import favoriteRoutesModel from './models/favoriteRoutes';

const storeModel = {
  global: globalModel,
  login: loginModel,
  notifications: notificationsModel,
  routes: routesModel,
  reservations: reservationsModel,
  reservationDetail: reservationDetailModel,
  places: placesModel,
  countries: countriesModel,
  vehicles: vehiclesModel,
  schedules: schedulesModel,
  favoriteRoutes: favoriteRoutesModel,
};

const store = createStore(storeModel);

export default store;
