import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, withTheme } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import QRCode from 'qrcode.react';

import CustomAppBar from '../components/CustomAppBar';

import { useTranslation } from 'react-multi-lang';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  info: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5),
    textAlign: 'center',
  },
  qr: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
    '& canvas': {
      border: `2px solid ${ theme.palette.divider }`,
      borderRadius: '8px',
    },
  }
}));

const TripCard = (props) => {
  const classes = useStyles();

  const t = useTranslation();

  return (
    <Dialog className={ classes.root } fullScreen open={ props.open } onClose={ props.toggle } TransitionComponent={ Transition }>
      <CustomAppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={ props.toggle } aria-label="close">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={ classes.title }>
            { t('tripCard.label') }
          </Typography>
        </Toolbar>
      </CustomAppBar>
      <Toolbar />
      <Typography className={ classes.info } variant="subtitle1"  component="p">
        { t('tripCard.info') }
      </Typography>
      <div className={ classes.qr }>
        <QRCode
          value={ JSON.stringify({ moveOnPassengerId: localStorage.getItem('id') }) }
          size={ 300 }
          includeMargin
        />
      </div>
    </Dialog>
  );
}

TripCard.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default withTheme(TripCard);
