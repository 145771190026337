import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const updatePlace = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/places/${ payload.id }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    provinceId: payload.provinceId,
    countyId: payload.countyId,
    description: payload.description,
    stop: true,
    latitude: payload.latitude,
    longitude: payload.longitude,
  }
})
.then(response => {
  actions.setLoading(false);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export default updatePlace;
