import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const postReservation = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
  actions.setLoading(false);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const postCancelReservation = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations/${ payload.reservationId }/events`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    status: payload.status,
    notes: payload.notes
  }
})
.then(response => {
  actions.setLoadingCard(null);
  actions.storePostCancelReservation(payload);
})
.catch(error => {
  actions.setLoadingCard(null);
  actions.setError(error.response);
  logSentryEndpointError(error);
});
