import { action, thunk } from 'easy-peasy';

import { getVehicleTrips } from '../../services/vehicles/get';

const vehiclesModel = {
  vehicleTrips: [],
  loading: false,
  error: '',
  getVehicleTrips: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getVehicleTrips(actions, payload);
  }),
  storeGetVehicleTrips: action((state, payload) => {
    state.vehicleTrips = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default vehiclesModel;
