import * as mqtt from 'react-paho-mqtt';

let client;
let clientId = window.URL.createObjectURL(new Blob([])).split('/').pop();

export const mqttService = {
  connect: (
    topic = { ident: '', options: {} },
    onMessageArrived
  ) => {
    // called when client lost connection
    const onConnectionLost = responseObject => {
      if (responseObject.errorCode !== 0) {
        console.log('onMqttConnectionLost: ' + responseObject.errorMessage);
      }
    };

    // console.log("clientId: " + clientId);
    client = mqtt.connect(
      process.env.REACT_APP_MQTT_HOST,
      Number(process.env.REACT_APP_MQTT_PORT),
      clientId,
      onConnectionLost,
      onMessageArrived
    );

    client.connect({
      userName: process.env.REACT_APP_MQTT_USERNAME,
      onSuccess: () => {
        console.log('connected!');
        client.subscribe(`${ process.env.REACT_APP_MQTT_TOPIC }/${ topic.ident }`, topic.options);
      },
      useSSL: process.env.NODE_ENV === 'production'
    });
  },

  disconnect: () => {
    client.disconnect();
  }
}
