import { withTheme, styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

const CustomDialog = styled(Dialog)({
  '& .MuiPaper-rounded': {
    backgroundColor: props => props.theme.palette.background.default,
    borderRadius: props => props.theme.spacing(1.5),
  },
  '& .MuiDialogContent-root': {
    overflow: 'visible',
  },
});

export default withTheme(CustomDialog);
