import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { fieldToTextField } from 'formik-material-ui';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInput-underline': {
      paddingBottom: 5,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      paddingRight: '14px !important',
      '&:not(.Mui-error)': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.divider,
        },
        '& .MuiInputAdornment-root': {
          color: theme.palette.primary.light
        },
        '&:not(.Mui-disabled) .MuiInputAdornment-root': {
          color: theme.palette.primary.main
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '12px',
        borderWidth: '2px',
      },
    },
  },
}));

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const classes = useStyles();

  const { form: { setFieldTouched, setFieldValue } } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  return (
    <div className={ classes.root }>
      <Autocomplete
        { ...props }
        { ...field }
        onChange={ (_, value) => {
          setFieldValue(name, value);
          if (props.onChange) {
            props.onChange();
          }
        } }
        blurOnSelect
        onBlur={ () => {
          setTimeout(() => setFieldTouched([name], true));
        } }
        renderInput={ props => (
          <TextField { ...props } { ...textFieldProps } helperText={ helperText } error={ error } InputProps={{
            ...props.InputProps,
            readOnly: textFieldProps.readOnly === undefined ? true : textFieldProps.readOnly,
            endAdornment: (
              <InputAdornment position="end">
                { textFieldProps.icon }
              </InputAdornment>
            ),
          }} />
        )}
      />
    </div>
  );
}

export default FormikAutocomplete;
