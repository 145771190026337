import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const getNotifications = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/notifications`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetNotifications(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export default getNotifications;
