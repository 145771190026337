import { action, thunk } from 'easy-peasy';

import { getFunctionalAreas } from '../../services/login/get';
import { postLogin, postRegister, postChangePassword } from '../../services/login/post';

const loginModel = {
  item: {},
  loading: false,
  error: '',
  register: {},
  registerLoading: false,
  registerError: '',
  changePasswordLoading: false,
  changePasswordError: '',
  functionalAreas: [],
  postLogin: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await postLogin(actions, payload);
  }),
  storePostLogin: action((state, payload) => {
    state.item = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  getFunctionalAreas: thunk(async (actions, payload) => {
    actions.setError('');
    return await getFunctionalAreas(actions, payload);
  }),
  storeGetFunctionalAreas: action((state, payload) => {
    state.functionalAreas = payload;
  }),
  postRegister: thunk(async (actions, payload) => {
    actions.setRegisterLoading(true);
    actions.setRegisterError('');
    return await postRegister(actions, payload);
  }),
  storePostRegister: action((state, payload) => {
    state.register = payload;
  }),
  setRegisterLoading: action((state, payload) => {
    state.registerLoading = payload;
  }),
  setRegisterError: action((state, payload) => {
    state.registerError = payload;
  }),
  postChangePassword: thunk(async (actions, payload) => {
    actions.setChangePasswordLoading(true);
    actions.setChangePasswordError('');
    return await postChangePassword(actions, payload);
  }),
  setChangePasswordLoading: action((state, payload) => {
    state.changePasswordLoading = payload;
  }),
  setChangePasswordError: action((state, payload) => {
    state.changePasswordError = payload;
  }),
}

export default loginModel;
