import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';


import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Skeleton from '@material-ui/lab/Skeleton';

import { Formik, Form, Field } from 'formik';

import FormikRadioGroup from '../components/FormikRadioGroup';
import FormikAutocomplete from '../components/FormikAutocomplete';

import { formatTime, sortByTime, DEFAULT_SNACKBAR, handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';
import { useDebounce } from '../shared/hooks/useDebounce';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiSkeleton-text': {
      transform: 'none',
    },
  },
  input: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  btnSubmit: {
    display: 'flex',
    margin: `${ theme.spacing(3) }px auto`,
    minWidth: '200px',
    borderRadius: '24px',
  },
}));

const FavoriteRoute = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const routes = useStoreState(state => state.routes.allRoutes);
  const favoriteRoutes = useStoreState(state => state.favoriteRoutes.favoriteRoutes);
  const routesLoading = useStoreState(state => state.routes.allRoutesLoading);
  const routesSoftLoading = useStoreState(state => state.routes.allRoutesSoftLoading);
  // const routesError = useStoreState(state => state.routes.allRoutesError);
  const getRoutes = useStoreActions(actions => actions.routes.getAllRoutes);
  const getFavoriteRoutes = useStoreActions(actions => actions.favoriteRoutes.getFavoriteRoutes);
  const addFavoriteRoute = useStoreActions(actions => actions.favoriteRoutes.addFavoriteRoute);

  const routePlaces = useStoreState(state => state.places.routePlaces);
  const getRoutePlaces = useStoreActions(actions => actions.places.getRoutePlaces);
  const userPlaces = useStoreState(state => state.places.userPlaces);
  const getUserPlaces = useStoreActions(actions => actions.places.getUserPlaces);
  
  const routeSchedules = useStoreState(state => state.schedules.routeSchedules);
  const routeSchedulesLoading = useStoreState(state => state.schedules.routeSchedulesLoading);
  const getRouteSchedules = useStoreActions(actions => actions.schedules.getAllRoutesSchedules);
  
  const favoriteRoutesIds = favoriteRoutes.map(favoriteRoute => favoriteRoute.route.id);
  const [hasDirectionChanged, setHasDirectionChanged] = React.useState(false);
  const [initialRoutesLength, setInitialRoutesLength] = React.useState(0);
  const [routeName, setRouteName] = React.useState(null);
  const debouncedRouteName = useDebounce(routeName, 500);
  const [defaultDirection, setDefaultDirection] = React.useState('');

  const onGetRoutes = (payload = { loading: false, direction: '' }) => {
    getRoutes(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.allRoutesLoading && !routesState.allRoutesError) {
        // console.log(routesState.items);
        setSnackbar(DEFAULT_SNACKBAR);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.allRoutesLoading, error: routesState.allRoutesError }, props, setSnackbar, t);
      }
    });
  };

  const onGetRoutePlaces = (payload = '') => {
    getRoutePlaces(payload).then(() => {
      const placesState = store.getState().places;
      if (!placesState.routePlacesLoading && !placesState.routePlacesError) {
        // console.log(placesState.items);
      } else {
        handleEndpointErrors({ ...placesState, loading: placesState.routePlacesLoading, error: placesState.routePlacesError }, props, setSnackbar, t);
      }
    });
  };

  const onGetUserPlaces = () => {
    getUserPlaces().then(() => {
      const placesState = store.getState().places;
      if (!placesState.userPlacesLoading && !placesState.userPlacesError) {
        // console.log(placesState.items);
      } else {
        handleEndpointErrors({ ...placesState, loading: placesState.userPlacesLoading, error: placesState.userPlacesError }, props, setSnackbar, t);
      }
    });
  };

  const onGetRouteSchedules = (payload = '') => {
    getRouteSchedules(payload).then(() => {
      const schedulesState = store.getState().schedules;
      if (!schedulesState.routeSchedulesLoading && !schedulesState.routeSchedulesError) {
        // console.log(schedulesState.routeSchedules);
      } else {
        handleEndpointErrors({ ...schedulesState, loading: schedulesState.routeSchedulesLoading, error: schedulesState.routeSchedulesError }, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    if (favoriteRoutes.length >= 2) {
      props.history.push({ pathname: '/favorite-routes' });
    } else if (defaultDirection) {
      onGetRoutes({ loading: debouncedRouteName === null, direction: defaultDirection, textSearch: debouncedRouteName, active: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDirection]);

  React.useEffect(() => {
    const direction = formRef.current?.values?.direction;
    if (direction) {
      onGetRoutes({ loading: debouncedRouteName === null, direction, textSearch: debouncedRouteName, active: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRouteName]);

  React.useEffect(() => {
    if (debouncedRouteName === null || hasDirectionChanged) {
      setHasDirectionChanged(false);
      setInitialRoutesLength(routes.length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  React.useEffect(() => {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('favoriteRoute.routes.loading')  });
    onGetUserPlaces();

    getFavoriteRoutes().then(() => {
      const favoriteRoutesState = store.getState().favoriteRoutes;
      if (!favoriteRoutesState.loading && !favoriteRoutesState.error) {
        // console.log(favoriteRoutesState.favoriteRoutes);
        setDefaultDirection(!!favoriteRoutesState.favoriteRoutes.length ? favoriteRoutesState.favoriteRoutes[0].route.direction === 'in' ? 'out' : 'in' : 'in');
      } else {
        handleEndpointErrors(favoriteRoutesState, props, setSnackbar, t);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDirectionChange = (values) => {
    setHasDirectionChanged(true);
    onGetRoutes({ direction: values.direction === 'in' ? 'out' : 'in', active: true });
  };

  // eslint-disable-next-line array-callback-return
  const updateFields = (fields, setFieldValue, setFieldTouched, values) => fields.map(field => {
    field === 'route' && onDirectionChange(values);
    setFieldValue(`${ field }`, '', false);
    setFieldTouched(`${ field }`, false);
  });

  const getPlaceOptions = (values) => {
    const places = values?.route?.routeType?.name?.toLowerCase() === 'dtd' ? userPlaces : routePlaces || [];
    if (places.every(place => place.arrivalTime)) {
      return sortByTime(places, 'arrivalTime');
    }
    return places;
  };

  const getPlaceLabel = (direction, routeType) => {
    switch(routeType) {
      case 'DTD':
        if (direction === 'in') {
          return t('favoriteRoute.place.labelInDTD');
        } else {
          return t('favoriteRoute.place.labelOutDTD');
        }
      default:
        return t('favoriteRoute.place.labelPeripheral');
    }
  };

  const t = useTranslation();

  storeMenuTitle(t('favoriteRoute.pageTitle'));

  const directionOptions = [
    { label: t('favoriteRoute.direction.in'), value: 'in' },
    { label: t('favoriteRoute.direction.out'), value: 'out' }
  ];

  const formRef = React.useRef();

  return (
    defaultDirection && <Formik
      innerRef={ formRef }
      initialValues={{
        direction: defaultDirection,
        route: '',
        schedule: '',
        place: ''
      }}
      validate={values => {
        const errors = {};
        if ( !values.route ) {
          errors.route = t('favoriteRoute.route.error');
        }
        if ( !values.schedule ) {
          errors.schedule = t(`favoriteRoute.schedule.${ values.direction }.error`);
        }
        if ( !values.place ) {
          errors.place = t('favoriteRoute.place.error');
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const data = {
          userId: localStorage.getItem('id'),
          routeId: values.route.id,
          routeScheduleId: values.schedule.routeScheduleId,
          placeId: values.place.id
        };
        addFavoriteRoute(data).then(() => {
          const favoriteRoutesState = store.getState().favoriteRoutes;
          if (!favoriteRoutesState.loading && !favoriteRoutesState.error) {
            setSubmitting(false);
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('favoriteRoute.snackbar') });
            props.history.push({ pathname: '/favorite-routes' });
          } else {
            setSubmitting(false);
            handleEndpointErrors(favoriteRoutesState, props, setSnackbar, t, t('favoriteRoute.errorEntity'));
          }
        });
      }}
    >
      {({ setFieldValue, setFieldTouched, values, submitForm, isSubmitting }) => (
        <Form className={ classes.root }>

          { (routesLoading) ?
            <Skeleton className={ classes.input } variant="text" height={ 56 } />
          :
            <Field
              name="direction"
              component={ FormikRadioGroup }
              className={ classes.input }
              label={ t('favoriteRoute.direction.label') }
              options={ directionOptions }
              disabled={ isSubmitting || !!favoriteRoutes.length }
              onChange={() => {
                updateFields(['route', 'schedule', 'place'], setFieldValue, setFieldTouched, values);
              }}
            />
          }

          { (routesLoading) ?
            <Skeleton className={ classes.input } variant="text" height={ 56 } />
          :
            <Field
              component={ FormikAutocomplete }
              name="route"
              className={ classes.input }
              options={ routes ?
                routes.filter(route => route?.direction?.toLowerCase() === values?.direction && !favoriteRoutesIds.includes(route.id))
              : [] }
              // groupBy={ option => option.type }
              getOptionLabel={ option => option ? option.name : '' }
              textFieldProps={{
                label: t('favoriteRoute.route.label'),
                readOnly: initialRoutesLength >= 15 ? false : true,
                fullWidth: true,
                variant: 'outlined',
                icon: <DirectionsBusIcon />,
                onChange: event => {
                  setRouteName( event.target.value );
                }
              }}
              onFocus={() => {
                setRouteName('');
              }}
              noOptionsText={ routesSoftLoading ? t('global.noOptionsLoading') : undefined }
              onChange={() => {
                setTimeout(() => {
                  const formValues = formRef?.current?.values;
                  if (formValues?.route?.id) {
                    onGetRouteSchedules(formValues.route.id);
                    if (!(formValues.route?.routeType?.name?.toLowerCase() === 'dtd')) {
                      onGetRoutePlaces(formValues.route.id);
                    }
                  }
                });
                updateFields(['schedule', 'place'], setFieldValue, setFieldTouched, values);
              }}
            />
          }

          { (routesLoading) ?
            <Skeleton className={ classes.input } variant="text" height={ 56 } />
          :
            <Field
              component={ FormikAutocomplete }
              name="schedule"
              className={ classes.input }
              options={ values.route ? routeSchedules.filter(schedule => schedule.routeId === values.route.id) || [] : [] }
              getOptionLabel={ option => option ? `${ values.direction === 'in' ? formatTime(option.arrivalTime) : formatTime(option.departureTime) }` : '' }
              textFieldProps={{ label: t(`favoriteRoute.schedule.${ values.direction }.label`), fullWidth: true, variant: 'outlined', icon: <ScheduleIcon /> }}
              noOptionsText={ routeSchedulesLoading ? t('global.noOptionsLoading') : undefined }
              disabled={ isSubmitting || !values.route }
              onChange={() => {
                updateFields(['place'], setFieldValue, setFieldTouched, values);
              }}
            />
          }

          { (routesLoading) ?
            <Skeleton className={ classes.input } variant="text" height={ 56 } />
          :
            <Field
              component={ FormikAutocomplete }
              name="place"
              className={ classes.input }
              options={ values.route ? getPlaceOptions(values) || [] : [] }
              // groupBy={ option => option.type }
              getOptionLabel={ option => option ? `${ option.county.name } - ${ option.description }${ option.arrivalTime ? ` - ${ formatTime(option.arrivalTime) }` : '' }` : '' }
              textFieldProps={{ label: getPlaceLabel(values.direction, values.route?.routeType?.name), fullWidth: true, variant: 'outlined', icon: <LocationOnIcon /> }}
              disabled={ isSubmitting || !values.schedule }
            />
          }

          <Button
            className={ classes.btnSubmit }
            type="submit"
            disabled={ routesLoading || favoriteRoutes.length >= 2 || isSubmitting }
            onClick={ submitForm }
            variant="contained"
            color="primary"
          >{ t('favoriteRoute.button') }</Button>
        </Form>
      )}
    </Formik>
  );
}

FavoriteRoute.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(FavoriteRoute);
