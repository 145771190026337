import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.button,
    alignItems: 'center',
    border: `1px solid ${ theme.palette.divider }`,
    borderRadius: theme.spacing(3),
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: '10px',
    justifyContent: 'center',
    lineHeight: '10px',
    margin: theme.spacing(.5),
    padding: '10px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    '&:first-child': {
      marginLeft: 0
    },
    '&:last-child': {
      marginRight: 0
    },
  },
}));

const Tag = (props) => {
  const { name, additionalClass } = props;
  const classes = useStyles();

  return (
    <span className={clsx( classes.root, additionalClass )}>{ name }</span>
  );
}

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
};

export default Tag;
