import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  group: {
    flexDirection: 'row'
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
    [theme.breakpoints.up('md')]: {
      paddingRight: '40px'
    },
    '&:last-child': {
      paddingRight: 0
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.dark,
    },
    '& .Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  }
}));

const FormikRadioGroup = ({
  field,
  form: { touched, errors },
  name,
  options,
  ...props
}) => {
  const classes = useStyles();
  const fieldName = name || field.name;

  return (
    <div { ...props }>
      <Typography variant="overline" component="p">{ props.label }</Typography>
      <RadioGroup { ...field } className={ classes.group } name={ fieldName }>
        { options.map((option, index) => (
          <div key={ option.value } className={ classes.radio }>
            <FormControlLabel value={ option.value } control={ <Radio /> } label={ option.label } disabled={ props.disabled } />
            { props.icons ? props.icons[index] : null }
          </div>
        )) }
      </RadioGroup>

      { touched[fieldName] && errors[fieldName] && (
        <React.Fragment>{ errors[fieldName] }</React.Fragment>
      )}
    </div>
  );
};

export default FormikRadioGroup;
