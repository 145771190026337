import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStore, useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Carousel from 'react-material-ui-carousel';

import { handleEndpointErrors, formatDate, formatTime, SNACKBAR_TIME, ERRORS } from '../shared/utilities';

import QrReader from 'react-qr-reader';

import CustomDialog from './CustomDialog';
import CustomCard from './CustomCard';

import { useTranslation } from 'react-multi-lang';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ ref } { ...props } />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
    },
  },
  appBar: {
    position: 'relative',
    '& .MuiToolbar-root': {
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${ theme.palette.primary.light }`,
      color: theme.palette.text.secondary,
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  qrReader: {
    '& section': {
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        paddingTop: 'calc(100vh - 56px) !important'
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: 'calc(100vh - 64px) !important'
      }
    }
  },
  carousel: {
    overflow: 'visible',
    '& .MuiCard-root': {
      marginTop: 0,
    },
  },
}));

const QRReader = (props) => {
  const classes = useStyles();
  const { open, toggle } = props;
  const [vehicleId, setVehicleId] = React.useState(null);
  const [multipleTrips, setMultipleTrips] = React.useState([]);
  const [tripInViewIndex, setTripInViewIndex] = React.useState(0);
  const [multipleTripsModalOpen, setMultipleTripsModalOpen] = React.useState(false);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const getVehicleTrips = useStoreActions(actions => actions.vehicles.getVehicleTrips);
  const postReservation = useStoreActions(actions => actions.reservations.postReservation);
  const getReservations = useStoreActions(actions => actions.reservations.getReservations);

  const t = useTranslation();

  const clear = () => {
    toggle();
    setTimeout(() => setVehicleId(null), 1000);
  };

  const getTripPostValues = trip => ({
    tripId: trip.id,
    routeId: trip.route.id,
    passengerId: localStorage.getItem('id'),
    routeScheduleId: trip.schedule.id,
    dates: [trip.departureDate.split('T')[0]],
    placeId: trip.route.destination.id,
    status: ['pending', 'ready'].includes(trip.status) ? 'onboard' : 'underway',
    ignoreReservationWindow: true,
    ignoreVehicleCapacity: true,
    source: 'qr_code_passenger'
  });

  const postReservationWithTrip = trip => {
    if ( multipleTripsModalOpen ) {
      setMultipleTripsModalOpen(false);
      clear();
    }
    postReservation(getTripPostValues(trip)).then(() => {
      const reservationsState = store.getState().reservations;
      if (!reservationsState.loading && !reservationsState.error) {
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('reservations.snackbar.created') });
        if (props.location.pathname === '/reservations') {
          getReservations();
        } else {
          props.history.push({ pathname: '/reservations' });
        }
      } else {
        if (reservationsState.error.status === 460) {
          handleEndpointErrors(reservationsState, props, setSnackbar, t, 'create');
        } else {
          handleEndpointErrors(reservationsState, props, setSnackbar, t, t('reservations.errorEntity'));
        }
      }
    });
  };

  const handleQrLogic = id => {
    getVehicleTrips(id).then(() => {
      const vehiclesState = store.getState().vehicles;
      if (!vehiclesState.loading && !vehiclesState.error) {
        const trips = vehiclesState.vehicleTrips;
        if ( !trips.length ) {
          clear();
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('qrreader.noTripsAvailable')  });
        } else if (trips.length === 1) {
          clear();
          postReservationWithTrip(trips[0]);
        } else if ( trips.length > 1 ) {
          setMultipleTrips(trips);
          setMultipleTripsModalOpen(true);
        }
      } else {
        handleEndpointErrors(vehiclesState, props, setSnackbar, t);
      }
    });
  }

  const handleQrScan = (data, props) => {
    if (data && !vehicleId) {
      const id = +JSON.parse(data)?.moveOnVehicleId;
      setVehicleId(id);
      handleQrLogic(id);
    }
  };

  const handleQrError = error => {
    if (error.toString() === ERRORS.DEVICE_NOT_FOUND) {
      setSnackbar({ show: true, autoHideDuration: 8000, severity: 'error', message: t('global.errors.cameraNotFound') });
    } else if (error.toString() === ERRORS.PERMISSION_DENIED || error.toString() === ERRORS.PERMISSION_DISMISSED) {
      setSnackbar({ show: true, autoHideDuration: 8000, severity: 'error', message: t('global.errors.cameraNotEnabled') });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.qrreader') });
    }
  };

  const onMultipleTripsModalClose = () => {
    setMultipleTripsModalOpen(false);
    clear();
  };

  return (
    <Dialog className={ classes.root } fullScreen open={ open } onClose={ clear } TransitionComponent={ Transition }>
      <AppBar className={ classes.appBar }>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={ clear } aria-label="close">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" className={ classes.title }>
            { t('qrreader.label') }
          </Typography>
        </Toolbar>
      </AppBar>
      <QrReader
        delay={ 300 }
        onError={ handleQrError }
        onScan={ data => handleQrScan(data, props) }
        className={ classes.qrReader }
      />

      <CustomDialog open={ multipleTripsModalOpen } onClose={ onMultipleTripsModalClose } fullWidth={ true } maxWidth="md">
        <DialogTitle>{ t('qrreader.multipleTripsModal.title') }</DialogTitle>
        <DialogContent>
          <Carousel
            className={ classes.carousel }
            autoPlay={ false }
            animation="slide"
            navButtonsAlwaysInvisible={ true }
            changeOnFirstRender
            onChange={ now => setTripInViewIndex(now) }
          >
            {
              multipleTrips.map((trip, index) => <CustomCard
                key={ trip.id }
                type="trip"
                route={{
                  origin: { name: trip.route.origin.description, time: formatTime(trip.schedule.departureTime) },
                  destination: { name: trip.route.destination.description, time: formatTime(trip.schedule.arrivalTime) },
                }}
                date={ `${ formatDate(trip.departureDate, t) }` }
                tripInfo={{
                  title: t('qrreader.multipleTripsModal.tripInfo'),
                  status: trip.status,
                  driver: trip.driver.name,
                  plate: trip.vehicle.licensePlate,
                  id: trip.id,
                }}
                largeBtn={{
                  text: t('qrreader.multipleTripsModal.btnSelectTrip'),
                  handler: () => postReservationWithTrip(multipleTrips[tripInViewIndex])
                }}
              />)
            }
          </Carousel>
        </DialogContent>
        <DialogActions>
          <Button onClick={ onMultipleTripsModalClose } color="primary">{ t('qrreader.multipleTripsModal.btnClose') }</Button>
        </DialogActions>
      </CustomDialog>
    </Dialog>
  );
}

QRReader.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default withRouter(QRReader);
