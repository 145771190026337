import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getProvinces = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/countries/${ localStorage.getItem('countryId') }/provinces`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetProvinces(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getCounties = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/countries/${ localStorage.getItem('countryId') }/provinces/${ payload }/counties`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetCounties(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});
