import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BackgroundIllustration from './BackgroundIllustration';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    left: 0,
    position: 'fixed',
    textAlign: 'center',
    top: 0,
    width: '100%',
    zIndex: 9999,
    '& .MuiTypography-root': {
      left: 0,
      margin: '120px auto',
      padding: theme.spacing(2),
      position: 'absolute',
      textAlign: 'center',
      top: 0,
      width: '100%',
      [`${ theme.breakpoints.down('md') } and (orientation: landscape)`]: {
        marginTop: '52px',
        padding: theme.spacing(2, 10),
      },
      [`${ theme.breakpoints.up('md') } and (orientation: landscape)`]: {
        marginTop: '140px',
      },
    },
  },
}));

const UpdateAppOverlay = props => {
  const classes = useStyles();

  const t = useTranslation();

  props.clearLocalStorage();

  return (
    <div className={ classes.root }>
      <BackgroundIllustration />
      <Typography variant="h6">{ t('global.update') }</Typography>
    </div>
  );
}

UpdateAppOverlay.propTypes = {
  clearLocalStorage: PropTypes.func.isRequired,
};

export default UpdateAppOverlay;
