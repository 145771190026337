import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Fab from '@material-ui/core/Fab';

import CustomCard from '../components/CustomCard';
import LoadingCard from '../components/LoadingCard';

import { formatTime, handleEndpointErrors } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    '& .MuiTypography-h6': {
      lineHeight: 1.2,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
    },
  },
  fab: {
    bottom: '24px',
    position: 'fixed',
    right: '24px',
  }
}));

const FavoriteRoutes = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const favoriteRoutes = useStoreState(state => state.favoriteRoutes.favoriteRoutes);
  const favoriteRoutesLoading = useStoreState(state => state.favoriteRoutes.loading);
  // const favoriteRoutesError = useStoreState(state => state.favoriteRoutes.error);
  const getFavoriteRoutes = useStoreActions(actions => actions.favoriteRoutes.getFavoriteRoutes);
  // const storeGetFavoriteRoutes = useStoreActions(actions => actions.favoriteRoutes.storeGetFavoriteRoutes);
  // const deleteFavoriteRoute = useStoreActions(actions => actions.favoriteRoutes.deleteFavoriteRoute);

  React.useEffect(() => {
    getFavoriteRoutes().then(() => {
      const favoriteRoutesState = store.getState().favoriteRoutes;
      if (!favoriteRoutesState.loading && !favoriteRoutesState.error) {
        // console.log(favoriteRoutesState.favoriteRoutes);
      } else {
        handleEndpointErrors(favoriteRoutesState, props, setSnackbar, t);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onDeleteHandler = (favoriteRouteId) => event  => {
  //   const favoriteRouteIndex = favoriteRoutes.findIndex(route => route.id === favoriteRouteId);
  //   const updatedRoutes = [...favoriteRoutes];
  //   updatedRoutes[favoriteRouteIndex].isDeleting = true;
  //   storeGetFavoriteRoutes(updatedRoutes);

  //   deleteFavoriteRoute(favoriteRouteId).then(() => {
  //     const favoriteRoutesState = store.getState().favoriteRoutes;
  //     if (!favoriteRoutesState.error) {
  //       // console.log('success', favoriteRoutesState);
  //     } else {
  //       handleEndpointErrors(favoriteRoutesState, props, setSnackbar, t);
  //     }
  //   });
  // }

  const onAddHandler = () => {
    props.history.push({ pathname: '/favorite-routes/add' });
  }

  const t = useTranslation();

  storeMenuTitle(t('favoriteRoutes.pageTitle'));

  return (
    <div className={ classes.root }>
      { favoriteRoutesLoading ? <LoadingCard length={ 5 } height={ 207 } /> :
        favoriteRoutes.length ? favoriteRoutes.map(favoriteRoute => (
          favoriteRoute.isDeleting ?
            <LoadingCard key={ favoriteRoute.id } length={ 1 } height={ 207 } /> :
            <CustomCard
              key={ favoriteRoute.id }
              direction={ favoriteRoute.route.direction }
              route={{
                name: favoriteRoute.route.name,
                origin: { name: favoriteRoute.route.origin.description, time: formatTime(favoriteRoute.routeSchedule.departureTime) },
                destination: { name: favoriteRoute.route.destination.description, time: formatTime(favoriteRoute.routeSchedule.arrivalTime) },
              }}
              body={ `${ t(`favoriteRoutes.card.place.${ favoriteRoute?.route?.direction?.toLowerCase() === 'in' ?
                'in' : 'out' }`) }: ${ favoriteRoute.place.description }` }
              // primaryIconBtn={{
              //   icon: <DeleteOutlineIcon color="primary" />,
              //   handler: onDeleteHandler(favoriteRoute.id),
              //   backText: t('favoriteRoutes.card.backCard.btnDelete')
              // }}
              // secondaryBtn={{
              //   backText: t('locations.card.backCard.btnCancel')
              // }}
              // backMsg={ t('favoriteRoutes.card.backCard.msgDelete') }
              // backPaddingBottom="37px"
              // backIcon={ <DeleteOutlineIcon /> }
            />
        )) : (
          <CustomCard type="notFound" notFoundMsg={ t('favoriteRoutes.card.notFound') } />
        )
      }
      { favoriteRoutes.length >= 2 && <Alert severity="info">{ t('favoriteRoutes.alert') }</Alert> }
      <Fab className={ classes.fab } color="primary" aria-label="add" onClick={ onAddHandler } disabled={ favoriteRoutesLoading || favoriteRoutes.length >= 2 }>
        <AddIcon />
      </Fab>
    </div>
  );
}

FavoriteRoutes.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(FavoriteRoutes);
