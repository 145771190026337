import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { PASSWORD_REGEX, handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';

import CustomFormikForm from '../components/CustomFormikForm';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 2, 4),
    '& ul': {
      columnCount: 1,
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
    },
  },
  input: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  passwordConditions: {
    marginTop: theme.spacing(-2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  btnWrapper: {
    position: 'relative',
  },
  btnSubmit: {
    borderRadius: '40px',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    margin: `40px auto 0`,
    minHeight: '36px',
    minWidth: '259px',
    '&.Mui-disabled': {
      backgroundColor: `${ theme.palette.text.disabled } !important`,
    },
  },
  btnProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ChangePassword = (props) => {
  const classes = useStyles();
  const t = useTranslation();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const postChangePassword = useStoreActions(actions => actions.login.postChangePassword);
  const changePasswordLoading = useStoreState(state => state.login.changePasswordLoading);

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);

  storeMenuTitle(t('changePassword.pageTitle'));

  return (
    <div className={ classes.root }>
      <Formik
        initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
        validationSchema={ Yup.object({
          oldPassword: Yup.string()
            .required(t('global.errors.required', { field: t('changePassword.oldPassword') })),
          newPassword: Yup.string()
            .matches(PASSWORD_REGEX, t('global.errors.password', { field: t('changePassword.newPassword') }))
            .required(t('global.errors.required', { field: t('changePassword.newPassword') })),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], t('changePassword.confirmPassword.error'))
            .required(t('global.errors.required', { field: t('changePassword.confirmPassword.label') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = { newPassword: values.newPassword, oldPassword: values.oldPassword };
          postChangePassword(data).then(() => {
            const loginState = store.getState().login;
            if (!loginState.changePasswordLoading && !loginState.changePasswordError) {
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('changePassword.changeSuccess') });
              setTimeout(() => { props.history.push({ pathname: '/' }); }, 1000);
            } else {
              handleEndpointErrors(loginState, props, setSnackbar, t);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <CustomFormikForm>
            <Grid container spacing={ 3 } direction="column" alignItems="center">
            <Grid className={classes.input} item><Field
                component={ TextField }
                type="password"
                name="oldPassword"
                label={ t('changePassword.oldPassword') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              <Grid className={classes.input} item><Field
                component={ TextField }
                type="password"
                name="newPassword"
                label={ t('changePassword.newPassword') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
              <Grid className={classes.passwordConditions} item>
                <ul>
                  { JSON.parse( t('global.errors.passwordConditions') ).map(condition => <li key={ condition }>- { condition }</li>) }
                </ul>
              </Grid>
              <Grid className={classes.input} item><Field
                component={ TextField }
                type="password"
                name="confirmPassword"
                label={ t('changePassword.confirmPassword.label') }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              /></Grid>
            </Grid>
            <div className={ classes.btnWrapper }>
              <Button
                className={ classes.btnSubmit }
                color="primary"
                variant="contained"
                type="submit"
                disabled={ isSubmitting }
              >{ t('changePassword.button') }</Button>
              { changePasswordLoading && <CircularProgress size={ 24 } className={ classes.btnProgress } /> }
            </div>
          </CustomFormikForm>
        )}
      </Formik>
    </div>
  );
}

ChangePassword.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default withRouter(ChangePassword);
