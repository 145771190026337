import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const postLogin = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/auth/login`,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Content-Type': 'application/json'
  // },
  data: {
    username: payload.username,
    password: payload.password
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storePostLogin(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const postRegister = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/users`,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Content-Type': 'application/json'
  // },
  data: payload
})
.then(response => {
  actions.setRegisterLoading(false);
  actions.storePostRegister(response.data);
})
.catch(error => {
  actions.setRegisterLoading(false);
  actions.setRegisterError(error.response);
  logSentryEndpointError(error);
});

export const postChangePassword = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/auth/change_password`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
  actions.setChangePasswordLoading(false);
})
.catch(error => {
  actions.setChangePasswordLoading(false);
  actions.setChangePasswordError(error.response);
  logSentryEndpointError(error);
});