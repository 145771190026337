import { action, thunk } from 'easy-peasy';

import getNotifications from '../../services/notifications/get';
import updateNotifications from '../../services/notifications/put';

const notificationsModel = {
  items: [],
  loading: false,
  error: '',
  getNotifications: thunk(async (actions) => {
    actions.setLoading(true);
    actions.setError('');
    return await getNotifications(actions);
  }),
  updateNotifications: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await updateNotifications(actions, payload);
  }),
  storeGetNotifications: action((state, payload) => {
    state.items = payload.filter(notification => notification.dispatchableType === 'Reservation');
  }),
  storeUpdateNotifications: action((state) => {
    // eslint-disable-next-line array-callback-return
    state.items.map(notification => {
      notification.UserNotification.read = true;
      notification.UserNotification.seen = true;
    });
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default notificationsModel;
