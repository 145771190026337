import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const updateNotifications = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/notifications`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
  actions.setLoading(false);
  payload.length > 1 && actions.storeUpdateNotifications();
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export default updateNotifications;
