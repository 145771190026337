import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getFavoriteRoutes = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/favorite_routes`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetFavoriteRoutes(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getAllRoutes = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/routes`,
  params: {
    filter: JSON.stringify({
      direction: [payload],
    }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setAllRoutesLoading(false);
  actions.setAllRoutesSoftLoading(false);
  actions.storeGetAllRoutes(response.data);
})
.catch(error => {
  actions.setAllRoutesLoading(false);
  actions.setAllRoutesSoftLoading(false);
  actions.setAllRoutesError(error.response);
  logSentryEndpointError(error);
});
