import { action, thunk } from 'easy-peasy';

import { getPlaces, getPlace, getRoutePlaces, getUserPlaces } from '../../services/places/get';
import postPlace from '../../services/places/post';
import updatePlace from '../../services/places/put';
import { deleteUserPlace, deletePlace } from '../../services/places/delete';

const placesModel = {
  items: [],
  currentItem: {},
  loading: false,
  loadingCard: null,
  error: '',
  routePlaces: [],
  routePlacesLoading: false,
  routePlacesError: '',
  userPlaces: [],
  userPlacesLoading: false,
  userPlacesError: '',
  getPlaces: thunk(async (actions) => {
    actions.setLoading(true);
    actions.setError('');
    return await getPlaces(actions);
  }),
  getPlace: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getPlace(actions, payload);
  }),
  getRoutePlaces: thunk(async (actions, payload) => {
    actions.setRoutePlacesLoading(true);
    actions.setRoutePlacesError('');
    return await getRoutePlaces(actions, payload);
  }),
  getUserPlaces: thunk(async (actions, payload) => {
    actions.setUserPlacesLoading(true);
    actions.setUserPlacesError('');
    return await getUserPlaces(actions, payload);
  }),
  postPlace: thunk(async (actions, payload) => {
    actions.setLoading(true);
    await postPlace(actions, payload);
  }),
  updatePlace: thunk(async (actions, payload) => {
    actions.setLoading(true);
    await updatePlace(actions, payload);
  }),
  deleteUserPlace: thunk(async (actions, payload) => {
    await deleteUserPlace(actions, payload);
  }),
  deletePlace: thunk(async (actions, payload) => {
    actions.setLoadingCard(payload);
    await deletePlace(actions, payload);
  }),
  storeGetPlaces: action((state, payload) => {
    state.items = payload;
  }),
  storeGetPlace: action((state, payload) => {
    state.currentItem = payload;
  }),
  storeGetRoutePlaces: action((state, payload) => {
    state.routePlaces = payload;
  }),
  storeGetUserPlaces: action((state, payload) => {
    state.userPlaces = payload;
  }),
  storeDeletePlace: action((state, payload) => {
    state.items = state.items.filter( place => place.id !== payload );
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setLoadingCard: action((state, payload) => {
    state.loadingCard = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setRoutePlacesLoading: action((state, payload) => {
    state.routePlacesLoading = payload;
  }),
  setRoutePlacesError: action((state, payload) => {
    state.routePlacesError = payload;
  }),
  setUserPlacesLoading: action((state, payload) => {
    state.userPlacesLoading = payload;
  }),
  setUserPlacesError: action((state, payload) => {
    state.userPlacesError = payload;
  }),
}

export default placesModel;
