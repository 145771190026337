import { action, thunk } from 'easy-peasy';

import getReservationDetail from '../../services/reservationDetail/get';

const reservationDetailModel = {
  item: {},
  loading: false,
  error: '',
  getReservationDetail: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getReservationDetail(actions, payload);
  }),
  storeGetReservationDetail: action((state, payload) => {
    state.item = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  })
}

export default reservationDetailModel;
