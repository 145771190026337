import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation
} from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/browser';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
// import Typography from '@material-ui/core/Typography';

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import ChangePassword from './pages/ChangePassword';
import Reservations from './pages/Reservations';
import ReservationDetail from './pages/ReservationDetail';
import FavoriteRoutes from './pages/FavoriteRoutes';
import FavoriteRoute from './pages/FavoriteRoute';
import Locations from './pages/Locations';
import Location from './pages/Location';

import UpdateAppOverlay from './components/UpdateAppOverlay';
import UpdateSWAlert from './components/UpdateSWAlert';
import Menu from './components/Menu';
import ErrorBoundary from './components/ErrorBoundary';

// import firebase from 'firebase/app';
// import { getToken, onMessageListener } from './firebase';

import { WHITE_LABEL, DEFAULT_SNACKBAR, updateManifestAndIcons, SNACKBAR_TIME } from './shared/utilities';

import { useTranslation } from 'react-multi-lang';

function Alert(props) {
  return <MuiAlert elevation={ 6 } variant="filled" { ...props } />;
}

function App(props) {
  const [state, setState] = React.useState({
    newVersionAvailable: false,
    waitingWorker: {},
    auth: localStorage.getItem('token') !== null,
  });

  updateManifestAndIcons();

  // Push notifications state
  // const isPushNotificationsSupported = firebase.messaging.isSupported();
  // const [showNotification, setShowNotification] = React.useState(false);
  // const [notification, setNotification] = React.useState({ title: '', body: '' });

  const store = useStore();

  const globalVersion = useStoreState(state => state.global.version);
  const globalTheme = useStoreState(state => state.global.theme);
  const snackbar = useStoreState(state => state.global.snackbar);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const getVersion = useStoreActions(actions => actions.global.getVersion);
  const getUserMetadata = useStoreActions(actions => actions.global.getUserMetadata);

  const reqThemeImport = require.context('./white-label', true, /^\.\/.*\.js$/);
  const { lightTheme, darkTheme } = reqThemeImport(`./${ WHITE_LABEL }/theme.js`);

  React.useEffect(() => {
    getVersion().then(() => {
      const globalState = store.getState().global;
      if (!globalState.version.loading && !globalState.version.error) {
        // console.log(globalState);
      } else {
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.default') });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   isPushNotificationsSupported && state.auth && getToken();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // isPushNotificationsSupported && onMessageListener().then(payload => {
  //   setShowNotification(true);
  //   setNotification({ title: payload.notification.title, body: payload.notification.body })
  // }).catch(err => console.log('failed: ', err));

  const onServiceWorkerUpdate = (registration) => {
    setState({
      ...state,
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  const updateServiceWorker = () => {
    const { waitingWorker } = state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setState({ ...state, newVersionAvailable: false });
    window.location.reload();
  };

  if (process.env.NODE_ENV === 'production') {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
  }

  const clearLocalStorage = () => {
    const selectedTheme = localStorage.getItem('theme');
    const selectedLanguage = localStorage.getItem('language');
    const username = localStorage.getItem('username');
    localStorage.clear();
    selectedTheme && localStorage.setItem('theme', selectedTheme);
    selectedLanguage && localStorage.setItem('language', selectedLanguage);
    username && localStorage.setItem('username', username);
  };

  const handleAuth = () => (logout = true, showLogoutMsg = false) => {
    props.history.push({ pathname: '/' });

    if (state.auth) {
      clearLocalStorage();
    }

    if (showLogoutMsg) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('login.expiredSession') });
    }

    setState({ ...state, auth: !logout });
  };

  const userId = localStorage.getItem('id');
  const appVersion =  `v${ globalVersion.version } (${ process.env.REACT_APP_LAST_COMMIT_SHA })`;
  if (userId !== null) {
    const clarity = window?.clarity;
    Sentry.setUser({
      id: userId,
      username: localStorage.getItem('username'),
      appVersion,
    });

    if (typeof clarity === 'function') {
      clarity('set', 'userId', userId);
      clarity('set', 'appVersion', appVersion);
    };
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(DEFAULT_SNACKBAR);
  };

  // const handleNotificationSnackbarClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setShowNotification(false);
  // };

  const location = useLocation();

  const t = useTranslation();

  const renderApp = <React.Fragment>
    { state.auth && (
      <React.Fragment>
        <Menu logout={ handleAuth() } />

        {/* <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={ showNotification }
          autoHideDuration={ DEFAULT_SNACKBAR.autoHideDuration }
          transitionDuration={ 0 }
          onClose={ handleNotificationSnackbarClose }
          message={(
            <React.Fragment>
              <Typography variant="h6">{ notification.title }</Typography>
              <Typography variant="body2">{ notification.body }</Typography>
            </React.Fragment>
          )}
        /> */}

        <ErrorBoundary key={ location.pathname } t={ t }>
          <Switch>
            <Route exact path="/change-password">
              <ChangePassword logout={ handleAuth() } />
            </Route>
            <Route exact path="/reservations">
              <Reservations logout={ handleAuth() } />
            </Route>
            <Route path="/reservations/detail/:id">
              <ReservationDetail logout={ handleAuth() } />
            </Route>
            <Route exact path="/favorite-routes">
              <FavoriteRoutes logout={ handleAuth() } />
            </Route>
            <Route exact path="/favorite-routes/add">
              <FavoriteRoute logout={ handleAuth() } />
            </Route>
            <Route exact path="/locations">
              <Locations logout={ handleAuth() } />
            </Route>
            <Route exact path="/locations/add">
              <Location logout={ handleAuth() } />
            </Route>
            <Route exact path="/locations/edit/:id">
              <Location logout={ handleAuth() } />
            </Route>
            <Route exact path="/">
              <Home logout={ handleAuth() } />
            </Route>
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </ErrorBoundary>
      </React.Fragment>
    ) }

    { !state.auth && (
      <Switch>
        <Route exact path="/login">
          <Login login={ handleAuth() } />
        </Route>
        <Route exact path="/register">
          <Register login={ handleAuth() } />
        </Route>
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    ) }

    <Snackbar open={ snackbar.show } autoHideDuration={ snackbar.autoHideDuration } transitionDuration={ 0 } onClose={ handleSnackbarClose }>
      <Alert onClose={ handleSnackbarClose } severity={ snackbar.severity }>
        { snackbar.message }
      </Alert>
    </Snackbar>

    { state.newVersionAvailable && <UpdateSWAlert onClick={ updateServiceWorker } /> }
  </React.Fragment>;

  React.useEffect(() => {
    if ( state.auth ) {
      getUserMetadata();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={ globalTheme === 'dark' ? darkTheme : lightTheme }>
      <CssBaseline />
      { globalVersion.loading ? <LinearProgress /> : (
        !globalVersion.update ? renderApp : <UpdateAppOverlay clearLocalStorage={ clearLocalStorage } />
      ) }
    </ThemeProvider>
  );
}

export default withRouter(App);
