import { action, thunk } from 'easy-peasy';

import { getTripsRoutes, getAllRoutes } from '../../services/routes/get';

const routesModel = {
  tripsRoutes: [],
  tripsRoutesLoading: false,
  tripsRoutesSoftLoading: false,
  tripsRoutesError: '',
  tripsRoutesTextSearch: '',
  allRoutes: [],
  allRoutesLoading: false,
  allRoutesSoftLoading: false,
  allRoutesError: '',
  allRoutesTextSearch: '',
  getTripsRoutes: thunk(async (actions, payload) => {
    actions.setTripsRoutesLoading(payload.loading);
    actions.setTripsRoutesSoftLoading(true);
    actions.setTripsRoutesError('');
    return await getTripsRoutes(actions, payload);
  }),
  getAllRoutes: thunk(async (actions, payload) => {
    actions.setAllRoutesLoading(payload.loading);
    actions.setAllRoutesSoftLoading(true);
    actions.setAllRoutesError('');
    return await getAllRoutes(actions, payload);
  }),
  storeGetTripsRoutes: action((state, payload) => {
    state.tripsRoutes = payload.data;
    state.tripsRoutesTextSearch = payload.textSearch;
  }),
  storeGetAllRoutes: action((state, payload) => {
    state.allRoutes = payload.data;
    state.allRoutesTextSearch = payload.textSearch;
  }),
  setTripsRoutesLoading: action((state, payload) => {
    state.tripsRoutesLoading = payload;
  }),
  setTripsRoutesSoftLoading: action((state, payload) => {
    state.tripsRoutesSoftLoading = payload;
  }),
  setTripsRoutesError: action((state, payload) => {
    state.tripsRoutesError = payload;
  }),
  setTripsRoutesTextSearch: action((state, payload) => {
    state.tripsRoutesTextSearch = payload;
  }),
  setAllRoutesLoading: action((state, payload) => {
    state.allRoutesLoading = payload;
  }),
  setAllRoutesSoftLoading: action((state, payload) => {
    state.softAllRoutesLoading = payload;
  }),
  setAllRoutesError: action((state, payload) => {
    state.allRoutesError = payload;
  }),
  setAllRoutesTextSearch: action((state, payload) => {
    state.allRoutesTextSearch = payload;
  }),
}

export default routesModel;
