import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '8px',
      color: theme.palette.common.white,
      flexWrap: 'nowrap',
    },
  },
}));

const UpdateSWAlert = (props) => {
  const { onClick } = props;
  const classes = useStyles();

  const t = useTranslation();

  return (
    <Snackbar
      open={ true }
      className={ classes.root }
      transitionDuration={ 0 }
      message={ t('serviceWorker.message') }
      action={
        <Button color="inherit" size="small" onClick={ onClick }>
          { t('serviceWorker.action') }
        </Button>
      }>
    </Snackbar>
  );
}

UpdateSWAlert.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UpdateSWAlert;
