import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getTripsRoutesSchedules = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/routes/${ payload }/trips/schedules`,
  params: {
    ...(localStorage.getItem('clientRsvAutoCreate') === 'true' && { extra: true }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRouteSchedulesLoading(false);
  actions.storeGetRouteSchedules(response.data);
})
.catch(error => {
  actions.setRouteSchedulesLoading(false);
  actions.setRouteSchedulesError(error.response);
  logSentryEndpointError(error);
});

export const getAllRoutesSchedules = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/routes/${ payload }/schedules`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRouteSchedulesLoading(false);
  actions.storeGetRouteSchedules(response.data);
})
.catch(error => {
  actions.setRouteSchedulesLoading(false);
  actions.setRouteSchedulesError(error.response);
  logSentryEndpointError(error);
});
