import React from 'react';

import { makeStyles, withTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `62px solid ${ theme.palette.menuIllustration }`,
    bottom: '0',
    left: '0',
    minWidth: '100%',
    position: 'absolute',
    [`${ theme.breakpoints.down('sm') } and (orientation: landscape)`]: {
      display: 'none',
    },
  },
}));

const MenuIllustration = props => {
  const { theme } = props;
  const classes = useStyles();

  return (
    <svg className={ classes.root } viewBox="0 0 304 304" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26687_49110)">
        <rect x="79.787" y="209.707" width="111.863" height="111.863" rx="23.5" transform="rotate(45 79.787 209.707)" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <rect x="201.584" y="186.724" width="145.442" height="145.442" rx="23.5" transform="rotate(45 201.584 186.724)" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <rect x="148.209" y="167.907" width="162.236" height="162.236" rx="23.5" transform="rotate(45 148.209 167.907)" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M251.957 220.611C251.957 219.336 250.923 218.303 249.649 218.303C248.374 218.303 247.34 219.336 247.34 220.611V244.86C244.435 243.893 242.339 241.153 242.339 237.922L242.339 234.46C242.339 233.185 241.306 232.152 240.031 232.152C238.756 232.152 237.723 233.185 237.723 234.46L237.723 237.922C237.723 243.719 241.859 248.55 247.34 249.625V259.081C247.34 260.355 248.374 261.389 249.649 261.389C250.923 261.389 251.957 260.355 251.957 259.081V242.097C257.788 241.517 262.343 236.597 262.343 230.613L262.343 226.766C262.343 225.491 261.309 224.458 260.035 224.458C258.76 224.458 257.727 225.491 257.727 226.766L257.727 230.613C257.727 234.044 255.231 236.892 251.957 237.442V220.611Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M62.6789 237.154C62.6789 236.304 61.99 235.615 61.1402 235.615C60.2903 235.615 59.6014 236.304 59.6014 237.154L59.6014 257.543C59.6014 258.392 60.2903 259.081 61.1402 259.081C61.99 259.081 62.6789 258.392 62.6789 257.543L62.6789 251.764C67.5994 251.562 71.5265 247.51 71.5265 242.54V239.462C71.5265 238.612 70.8375 237.923 69.9877 237.923C69.1378 237.923 68.4489 238.612 68.4489 239.462V242.54C68.4489 245.81 65.8989 248.484 62.6789 248.683L62.6789 237.154Z" fill={ theme.palette.menuIllustration }/>
        <path d="M46.55 257.95H257.45C282.716 257.95 303.229 278.298 303.497 303.5H0.502658C0.770766 278.298 21.2841 257.95 46.55 257.95Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.8716 163.291C47.8236 163.291 38.8674 172.248 38.8674 183.296V235.999H35.7892V248.309H39.0732L39.0735 248.31H32.7119V272.93H48.0998V248.31H41.7389L41.7392 248.309H45.0219V235.999H41.945V183.296C41.945 173.947 49.5233 166.369 58.8716 166.369H65.4108V177.141C65.4108 177.99 66.0998 178.679 66.9496 178.679C67.7995 178.679 68.4884 177.99 68.4884 177.141V166.369H83.8763V177.141C83.8763 177.99 84.5653 178.679 85.4151 178.679C86.2649 178.679 86.9539 177.99 86.9539 177.141V166.369H98.1106C98.9605 166.369 99.6494 165.68 99.6494 164.83C99.6494 163.98 98.9605 163.291 98.1106 163.291H85.4151H66.9496H58.8716Z" fill={ theme.palette.menuIllustration }/>
        <rect x="58.2172" y="177.641" width="35.9308" height="35.9308" rx="7.5" stroke={ theme.palette.menuIllustration }/>
        <path d="M67.9761 199.709C67.9761 200.612 68.3762 201.423 69.002 201.987V203.813C69.002 204.377 69.4636 204.839 70.0278 204.839H71.0537C71.6179 204.839 72.0796 204.377 72.0796 203.813V202.787H80.2864V203.813C80.2864 204.377 80.748 204.839 81.3123 204.839H82.3381C82.9023 204.839 83.364 204.377 83.364 203.813V201.987C83.9898 201.423 84.3898 200.612 84.3898 199.709V189.451C84.3898 185.86 80.7173 185.347 76.183 185.347C71.6487 185.347 67.9761 185.86 67.9761 189.451V199.709ZM71.5666 200.735C70.7152 200.735 70.0278 200.048 70.0278 199.197C70.0278 198.345 70.7152 197.658 71.5666 197.658C72.4181 197.658 73.1054 198.345 73.1054 199.197C73.1054 200.048 72.4181 200.735 71.5666 200.735ZM80.7993 200.735C79.9479 200.735 79.2606 200.048 79.2606 199.197C79.2606 198.345 79.9479 197.658 80.7993 197.658C81.6508 197.658 82.3381 198.345 82.3381 199.197C82.3381 200.048 81.6508 200.735 80.7993 200.735ZM82.3381 194.58H70.0278V189.451H82.3381V194.58Z" fill={ theme.palette.menuIllustration }/>
        <path opacity="0.4" d="M79.81 286.55C79.81 288.627 116.74 290.31 162.295 290.31C207.85 290.31 244.78 288.627 244.78 286.55C244.78 284.473 207.85 282.79 162.295 282.79C116.74 282.79 79.81 284.473 79.81 286.55Z" fill={ theme.palette.menuIllustration }/>
        <path d="M226.42 274.8C226.42 268.846 221.594 264.02 215.64 264.02H210C204.046 264.02 199.22 268.846 199.22 274.8C199.22 280.754 204.046 285.58 210 285.58H215.64C221.594 285.58 226.42 280.754 226.42 274.8Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path d="M145.11 274.8C145.11 268.846 140.284 264.02 134.33 264.02H128.69C122.737 264.02 117.91 268.846 117.91 274.8C117.91 280.754 122.737 285.58 128.69 285.58H134.33C140.284 285.58 145.11 280.754 145.11 274.8Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path d="M240.986 264.32C240.725 263.56 240.01 263.05 239.207 263.05H228.155C226.16 263.05 224.248 262.258 222.837 260.847L218.944 256.954C216.828 254.838 213.959 253.65 210.968 253.65H103.78C102.742 253.65 101.9 254.492 101.9 255.53V275.74C101.9 277.817 103.583 279.5 105.66 279.5H240.926C243.506 279.5 245.319 276.961 244.483 274.521L240.986 264.32Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M102.37 275.74V255.53C102.37 254.751 103.001 254.12 103.78 254.12H210.968C213.835 254.12 216.584 255.259 218.611 257.286L222.505 261.18C224.004 262.678 226.036 263.52 228.155 263.52H239.207C239.81 263.52 240.346 263.903 240.541 264.473L244.038 274.673C244.77 276.808 243.183 279.03 240.926 279.03H105.66C103.843 279.03 102.37 277.557 102.37 275.74ZM239.207 263.05C240.01 263.05 240.725 263.56 240.986 264.32L244.483 274.521C245.319 276.961 243.506 279.5 240.926 279.5H105.66C103.583 279.5 101.9 277.817 101.9 275.74V255.53C101.9 254.492 102.742 253.65 103.78 253.65H210.968C213.959 253.65 216.828 254.838 218.944 256.954L222.837 260.847C224.248 262.258 226.16 263.05 228.155 263.05H239.207Z" fill={ theme.palette.menuIllustration }/>
        <path d="M230.673 234.82C228.218 227.288 221.195 222.19 213.273 222.19H195.43C193.63 222.19 192.17 223.65 192.17 225.45V263.02H225.04H237.96C238.898 263.02 239.563 262.104 239.272 261.212L230.673 234.82Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path d="M143.495 253.15H143.521L143.547 253.153L184.281 257.418C186.205 257.619 187.88 256.11 187.88 254.176V225.45C187.88 223.65 186.42 222.19 184.62 222.19H102.37C100.57 222.19 99.11 223.65 99.11 225.45V251.77C99.11 252.532 99.7279 253.15 100.49 253.15H143.495Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path d="M209.058 234.666C206.537 226.927 199.322 221.69 191.183 221.69H173.34C171.264 221.69 169.58 223.373 169.58 225.45V263.52H202.95H215.87C217.148 263.52 218.054 262.273 217.658 261.058L209.058 234.666Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M175.937 227.403L182.987 249.023L183.433 248.877L176.383 227.257L175.937 227.403Z" fill={theme.palette.background.default}/>
        <path d="M169.58 225.45C169.58 223.373 167.897 221.69 165.82 221.69H83.57C81.4934 221.69 79.81 223.373 79.81 225.45V251.77C79.81 252.808 80.6517 253.65 81.69 253.65H124.695L165.428 257.915C167.648 258.148 169.58 256.407 169.58 254.176V225.45Z" fill={ theme.palette.menuIllustration }/>
        <path d="M218.431 264.941C218.236 264.389 217.715 264.02 217.13 264.02H206.173C203.978 264.02 201.879 263.12 200.365 261.531L196.522 257.496C194.487 255.359 191.666 254.15 188.716 254.15H81.69C80.9279 254.15 80.31 254.768 80.31 255.53V275.74C80.31 277.54 81.7696 279 83.57 279H218.786C221.035 279 222.608 276.776 221.86 274.655L218.431 264.941Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path d="M197.28 274.8C197.28 268.846 192.454 264.02 186.5 264.02H180.86C174.906 264.02 170.08 268.846 170.08 274.8C170.08 280.754 174.906 285.58 180.86 285.58H186.5C192.454 285.58 197.28 280.754 197.28 274.8Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path d="M116.91 274.8C116.91 268.846 112.084 264.02 106.13 264.02H100.49C94.5366 264.02 89.7102 268.846 89.7102 274.8C89.7102 280.754 94.5366 285.58 100.49 285.58H106.13C112.084 285.58 116.91 280.754 116.91 274.8Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path d="M192.14 274.8C192.14 268.57 187.09 263.52 180.86 263.52C174.63 263.52 169.58 268.57 169.58 274.8C169.58 281.03 174.63 286.08 180.86 286.08C187.09 286.08 192.14 281.03 192.14 274.8Z" fill={ theme.palette.menuIllustration }/>
        <path d="M111.77 274.8C111.77 268.57 106.72 263.52 100.49 263.52C94.2603 263.52 89.21 268.57 89.21 274.8C89.21 281.03 94.2603 286.08 100.49 286.08C106.72 286.08 111.77 281.03 111.77 274.8Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M189.32 274.8C189.32 279.472 185.533 283.26 180.86 283.26C176.188 283.26 172.4 279.472 172.4 274.8C172.4 270.128 176.188 266.34 180.86 266.34C185.533 266.34 189.32 270.128 189.32 274.8ZM180.86 265.4C186.052 265.4 190.26 269.609 190.26 274.8C190.26 279.991 186.052 284.2 180.86 284.2C175.669 284.2 171.46 279.991 171.46 274.8C171.46 269.609 175.669 265.4 180.86 265.4Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M108.95 274.8C108.95 279.472 105.162 283.26 100.49 283.26C95.8174 283.26 92.0298 279.472 92.0298 274.8C92.0298 270.128 95.8174 266.34 100.49 266.34C105.162 266.34 108.95 270.128 108.95 274.8ZM100.49 265.4C105.681 265.4 109.89 269.609 109.89 274.8C109.89 279.991 105.681 284.2 100.49 284.2C95.2983 284.2 91.0898 279.991 91.0898 274.8C91.0898 269.609 95.2983 265.4 100.49 265.4Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M138.807 227.403L145.857 249.023L146.303 248.877L139.253 227.257L138.807 227.403Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M101.676 227.403L108.726 249.023L109.173 248.877L102.123 227.257L101.676 227.403Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M202.95 265.87C202.431 265.87 202.01 266.291 202.01 266.81C202.01 272.261 206.429 276.68 211.88 276.68C212.399 276.68 212.82 276.259 212.82 275.74C212.82 275.221 212.399 274.8 211.88 274.8C207.467 274.8 203.89 271.223 203.89 266.81C203.89 266.291 203.469 265.87 202.95 265.87Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M206.945 254.59C208.185 254.59 209.06 255.501 209.06 256.47C209.06 256.989 209.481 257.41 210 257.41C210.519 257.41 210.94 256.989 210.94 256.47C210.94 254.324 209.079 252.71 206.945 252.71C204.811 252.71 202.95 254.324 202.95 256.47C202.95 256.989 203.371 257.41 203.89 257.41C204.409 257.41 204.83 256.989 204.83 256.47C204.83 255.501 205.704 254.59 206.945 254.59Z" fill={theme.palette.background.default}/>
        <path d="M197.31 237.67H186.97V247.54H197.31V237.67Z" fill={theme.palette.background.default}/>
        <path d="M198.216 234.901C198.632 234.277 198.184 233.44 197.434 233.44H186.846C186.096 233.44 185.648 234.277 186.064 234.901L186.97 236.26H197.31L198.216 234.901Z" fill={theme.palette.background.default}/>
        <path d="M197.629 236.38C197.418 236.301 197.195 236.26 196.969 236.26H186.97V237.67H201.07L197.629 236.38Z" fill={theme.palette.background.default}/>
        <path d="M190.26 240.49C190.26 241.009 190.681 241.43 191.2 241.43C191.719 241.43 192.14 241.009 192.14 240.49C192.14 239.971 191.719 239.55 191.2 239.55C190.681 239.55 190.26 239.971 190.26 240.49Z" fill={ theme.palette.menuIllustration }/>
        <path d="M194.96 240.49C194.96 241.009 195.381 241.43 195.9 241.43C196.419 241.43 196.84 241.009 196.84 240.49C196.84 239.971 196.419 239.55 195.9 239.55C195.381 239.55 194.96 239.971 194.96 240.49Z" fill={ theme.palette.menuIllustration }/>
        <path d="M156.735 247.946C157.065 248.693 158.124 248.693 158.454 247.946L163 237.67L152.19 237.67L156.735 247.946Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M156.42 241.195C156.809 241.195 157.125 241.511 157.125 241.9C157.125 242.03 157.23 242.135 157.36 242.135C157.49 242.135 157.595 242.03 157.595 241.9C157.595 241.251 157.069 240.725 156.42 240.725C155.771 240.725 155.245 241.251 155.245 241.9C155.245 242.03 155.35 242.135 155.48 242.135C155.61 242.135 155.715 242.03 155.715 241.9C155.715 241.511 156.031 241.195 156.42 241.195Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M160.18 241.195C160.569 241.195 160.885 241.511 160.885 241.9C160.885 242.03 160.99 242.135 161.12 242.135C161.25 242.135 161.355 242.03 161.355 241.9C161.355 241.251 160.829 240.725 160.18 240.725C159.531 240.725 159.005 241.251 159.005 241.9C159.005 242.03 159.11 242.135 159.24 242.135C159.37 242.135 159.475 242.03 159.475 241.9C159.475 241.511 159.791 241.195 160.18 241.195Z" fill={ theme.palette.menuIllustration }/>
        <path d="M156.42 244.25C156.42 245.029 157.051 245.66 157.83 245.66C158.608 245.66 159.24 245.029 159.24 244.25H156.42Z" fill={ theme.palette.menuIllustration }/>
        <path d="M163 236.73C163 235.692 162.158 234.85 161.12 234.85H154.07C153.032 234.85 152.19 235.692 152.19 236.73V237.67H163V236.73Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M192.683 243.779C192.47 243.637 192.375 243.467 192.375 243.31C192.375 243.18 192.27 243.075 192.14 243.075C192.01 243.075 191.905 243.18 191.905 243.31C191.905 243.673 192.125 243.972 192.423 244.17C192.721 244.369 193.121 244.485 193.55 244.485C193.979 244.485 194.379 244.369 194.677 244.17C194.975 243.972 195.195 243.673 195.195 243.31C195.195 243.18 195.09 243.075 194.96 243.075C194.83 243.075 194.725 243.18 194.725 243.31C194.725 243.467 194.63 243.637 194.417 243.779C194.205 243.92 193.899 244.015 193.55 244.015C193.201 244.015 192.895 243.92 192.683 243.779Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M157.219 231.606C157.371 231.945 157.396 232.383 157.363 232.683L157.36 232.709V234.85C157.36 235.11 157.57 235.32 157.83 235.32C158.089 235.32 158.3 235.11 158.3 234.85V232.76C158.341 232.355 158.314 231.75 158.076 231.221C157.952 230.944 157.763 230.672 157.48 230.469C157.193 230.264 156.838 230.15 156.42 230.15C156.013 230.15 155.666 230.248 155.381 230.429C155.097 230.61 154.902 230.856 154.772 231.113C154.52 231.614 154.497 232.185 154.543 232.558C154.576 232.816 154.811 232.999 155.068 232.966C155.326 232.934 155.508 232.699 155.476 232.442C155.444 232.188 155.469 231.82 155.611 231.537C155.679 231.402 155.769 231.296 155.886 231.222C156.001 231.149 156.168 231.09 156.42 231.09C156.66 231.09 156.82 231.153 156.933 231.233C157.05 231.317 157.145 231.442 157.219 231.606Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M121.164 231.437C121.399 231.726 121.536 232.142 121.581 232.441L121.585 232.467L122.139 234.535C122.207 234.785 122.464 234.934 122.715 234.867C122.966 234.8 123.115 234.542 123.047 234.291L122.506 232.272C122.441 231.871 122.259 231.294 121.892 230.843C121.701 230.608 121.447 230.394 121.122 230.272C120.791 230.148 120.419 230.13 120.015 230.238C119.623 230.343 119.312 230.528 119.084 230.777C118.857 231.024 118.732 231.313 118.673 231.595C118.558 232.144 118.684 232.702 118.826 233.05C118.924 233.29 119.198 233.406 119.438 233.308C119.679 233.211 119.795 232.936 119.697 232.696C119.6 232.459 119.529 232.097 119.593 231.787C119.624 231.64 119.683 231.514 119.777 231.412C119.869 231.311 120.015 231.211 120.258 231.146C120.49 231.084 120.661 231.103 120.791 231.152C120.926 231.203 121.05 231.298 121.164 231.437Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M125.24 231.437C125.005 231.726 124.868 232.142 124.823 232.441L124.819 232.467L124.265 234.535C124.197 234.785 123.94 234.934 123.689 234.867C123.438 234.8 123.289 234.542 123.357 234.291L123.898 232.272C123.963 231.871 124.145 231.294 124.512 230.843C124.703 230.608 124.957 230.394 125.282 230.272C125.613 230.148 125.985 230.13 126.389 230.238C126.782 230.343 127.092 230.528 127.32 230.777C127.547 231.024 127.672 231.313 127.731 231.595C127.846 232.144 127.72 232.702 127.578 233.05C127.48 233.29 127.206 233.406 126.966 233.308C126.725 233.211 126.61 232.936 126.707 232.696C126.804 232.459 126.876 232.097 126.811 231.787C126.78 231.64 126.721 231.514 126.627 231.412C126.535 231.311 126.389 231.211 126.146 231.146C125.914 231.084 125.743 231.103 125.613 231.152C125.478 231.203 125.354 231.298 125.24 231.437Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M118.82 246.13C117.689 244.836 116.94 242.798 116.94 240.803C116.94 236.866 119.676 233.675 123.05 233.675C126.425 233.675 129.16 236.866 129.16 240.803C129.16 242.798 128.412 244.836 127.28 246.13H118.82Z" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M125.331 243.379C125.24 243.287 125.091 243.287 124.999 243.379L124.019 244.359C123.743 244.635 123.297 244.635 123.021 244.359L122.041 243.379C121.949 243.287 121.8 243.287 121.709 243.379C121.617 243.471 121.617 243.619 121.709 243.711L122.689 244.692C123.148 245.151 123.892 245.151 124.351 244.692L125.331 243.711C125.423 243.619 125.423 243.471 125.331 243.379Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M120.021 241.259C120.113 241.351 120.262 241.351 120.353 241.259L120.65 240.963C120.925 240.687 121.372 240.687 121.647 240.963L121.944 241.259C122.036 241.351 122.184 241.351 122.276 241.259C122.368 241.168 122.368 241.019 122.276 240.927L121.98 240.63C121.521 240.171 120.777 240.171 120.318 240.63L120.021 240.927C119.929 241.019 119.929 241.168 120.021 241.259Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M125.191 241.259C125.283 241.351 125.432 241.351 125.523 241.259L125.82 240.963C126.095 240.687 126.542 240.687 126.817 240.963L127.114 241.259C127.205 241.351 127.354 241.351 127.446 241.259C127.538 241.168 127.538 241.019 127.446 240.927L127.149 240.63C126.69 240.171 125.946 240.171 125.488 240.63L125.191 240.927C125.099 241.019 125.099 241.168 125.191 241.259Z" fill={ theme.palette.menuIllustration }/>
        <rect width="8.46001" height="0.940002" transform="matrix(-1 0 0 1 127.28 246.13)" fill={theme.palette.background.default}/>
        <rect width="10.34" height="0.940002" transform="matrix(-1 0 0 1 197.31 247.54)" fill={theme.palette.background.default}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M212.8 112.1C212.871 110.331 214.228 108.92 215.897 108.92C216.287 108.92 216.659 109 217.003 109.141C218.056 106.95 220.186 105.45 222.645 105.45C224.754 105.45 226.621 106.553 227.774 108.249C228.473 107.76 229.306 107.474 230.203 107.474C232.617 107.474 234.578 109.533 234.65 112.1H212.8Z" fill={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M96.1457 124.45C92.1852 124.45 88.8049 126.769 87.46 130.036C86.4927 128.819 84.9499 128.027 83.206 128.027C80.2756 128.027 77.9 130.254 77.9 133H105.45C105.351 128.262 101.225 124.45 96.1457 124.45" fill={ theme.palette.menuIllustration }/>
        <path d="M174.3 117.325C174.3 129.379 164.529 139.15 152.475 139.15C140.421 139.15 130.65 129.379 130.65 117.325C130.65 105.271 140.421 95.5 152.475 95.5C164.529 95.5 174.3 105.271 174.3 117.325Z" fill={theme.palette.background.default} stroke={ theme.palette.menuIllustration }/>
        <path fillRule="evenodd" clipRule="evenodd" d="M166.117 144.4C172.126 144.4 177.255 148.007 179.295 153.09C180.763 151.196 183.104 149.964 185.749 149.964C190.196 149.964 193.8 153.428 193.8 157.7H152C152.151 150.33 158.411 144.4 166.117 144.4" fill={ theme.palette.menuIllustration }/>
      </g>
    </svg>
  );
}

export default withTheme(MenuIllustration);
