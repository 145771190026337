import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getPlaces = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/places`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetPlaces(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getPlace = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/places/${ payload }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetPlace(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getRoutePlaces = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/route_places`,
  params: {
    routeId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRoutePlacesLoading(false);
  actions.storeGetRoutePlaces(response.data);
})
.catch(error => {
  actions.setRoutePlacesLoading(false);
  actions.setRoutePlacesError(error.response);
  logSentryEndpointError(error);
});

export const getUserPlaces = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/user_places`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setUserPlacesLoading(false);
  actions.storeGetUserPlaces(response.data);
})
.catch(error => {
  actions.setUserPlacesLoading(false);
  actions.setUserPlacesError(error.response);
  logSentryEndpointError(error);
});
