import { withTheme, styled } from '@material-ui/core/styles';
import { Form } from 'formik';

const CustomFormikForm = styled(Form)((props) => ({
  '& .formik-checkbox': {
    [props.theme.breakpoints.down('md')]: {
      display: 'inline-block',
      marginBottom: props.theme.spacing(3),
      marginRight: props.theme.spacing(3),
    },
    [props.theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: props.theme.spacing(1.5),
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:not(.Mui-error)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: props.theme.palette.divider,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
      borderWidth: '2px',
    },
  },
}));

export default withTheme(CustomFormikForm);
