import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '12px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  }
}));

const Ticket = props => {
  const classes = useStyles();

  return (
    [...Array(props.length).keys()].map((_, index) => (
      <Skeleton key={ index } className={ classes.card } variant="rect" height={ props.height } />
    ))
  );
}

Ticket.propTypes = {
  length: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Ticket;
