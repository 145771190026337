import { action, thunk } from 'easy-peasy';

import { getFavoriteRoutes } from '../../services/favoriteRoutes/get';
import { addFavoriteRoute } from '../../services/favoriteRoutes/post';
import { deleteFavoriteRoute } from '../../services/favoriteRoutes/delete';

const favoriteRoutesModel = {
  favoriteRoutes: [],
  loading: false,
  error: '',
  getFavoriteRoutes: thunk(async (actions) => {
    actions.setLoading(true);
    actions.setError('');
    return await getFavoriteRoutes(actions);
  }),
  addFavoriteRoute: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    await addFavoriteRoute(actions, payload);
  }),
  deleteFavoriteRoute: thunk(async (actions, payload) => {
    await deleteFavoriteRoute(actions, payload);
  }),
  storeGetFavoriteRoutes: action((state, payload) => {
    state.favoriteRoutes = payload;
  }),
  storeDeleteFavoriteRoute: action((state, payload) => {
    state.favoriteRoutes = state.favoriteRoutes.filter( favoriteRoute => favoriteRoute.id !== payload );
  }),
  storeDeleteFavoriteRouteError: action((state, payload) => {
    const favoriteRouteIndex = state.favoriteRoutes.findIndex(route => route.id === payload);
    const updatedRoutes = [...state.favoriteRoutes];
    updatedRoutes[favoriteRouteIndex].isDeleting = false;
    state.favoriteRoutes = updatedRoutes;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default favoriteRoutesModel;
